import {
  FunctionComponent,
  PropsWithChildren,
  useCallback,
  useContext,
} from "react";
import { ButtonProps } from "@mui/material/Button";
import { Divider, NoSsr, Typography, styled } from "@mui/material";
import { EditorCtx } from "../../wysiwyg/EditorCtx";
import { useGoogleSSO, useMicrosoftSSO } from "../../dataAccess/SSO";
import GoogleIcon from "../../assets/svg/google-logo.svg";
import OutlookIcon from "../../assets/svg/outlook-logo.svg";
import { ThemeButton } from "./ThemeComponents/ThemeButton";
import { ProfileContext } from "../public/Profile";
import { iframe } from "../../helpers/environment";
import { openSSOPopup } from "../../app/ssoPopup";

interface Props {
  label?: string;
}

const StyledDivider = styled(Divider)(() => ({
  width: "100%",
  "&:before, &:after": {
    borderTopWidth: "2px",
    borderTopStyle: "dashed",
  },
}));

const Content = styled("div")(() => ({
  display: "flex",
  alignItems: "center",
  justifyContent: "center",
  gap: "1rem",
}));

const Main = styled("div")(() => ({
  display: "flex",
  flexDirection: "column",
  marginTop: "1.5rem",
  marginBottom: ".5rem",
  gap: "2rem",
}));

const Label = styled(Typography)(() => ({
  fontWeight: "bold",
  pointerEvents: "none",
  minWidth: "5rem",
}));

const StyledGoogleIcon = styled(GoogleIcon)(() => ({
  width: "1rem",
}));

const StyledOutlookIcon = styled(OutlookIcon)(() => ({
  width: "1rem",
  height: "1rem",
}));

const ButtonLabel = styled("span")(({ theme }) => ({
  display: "none",
  color: "inherit",
  [theme.breakpoints.up("md")]: {
    display: "block",
  },
}));

const StyledThemeButton = styled(ThemeButton)(({ theme }) => ({
  minWidth: 0,
  [theme.breakpoints.up("md")]: {
    minWidth: "8rem",
  },
  [theme.breakpoints.down("md")]: {
    "& .MuiButton-endIcon": {
      marginRight: 0,
      marginLeft: 0,
    },
  },
}));

const SSOIntegration: FunctionComponent<
  PropsWithChildren<Props & ButtonProps>
> = ({ label }) => {
  const { googleUrl, googleProvider } = useGoogleSSO();
  const { isEditor, messages, setCurrentMessage } = useContext(EditorCtx);
  const { setLoginOpen, setSignUpOpen } = useContext(ProfileContext);
  const { microsoftUrl, microsoftProvider } = useMicrosoftSSO();

  const onClick = useCallback(
    (e: React.MouseEvent<HTMLButtonElement>, url: string, provider: string) => {
      if (isEditor) {
        e.preventDefault();
        setCurrentMessage(messages.previewAlert);
        setLoginOpen(false);
        setSignUpOpen(false);
      } else if (iframe) {
        e.preventDefault();
        openSSOPopup(url, provider);
      }
    },
    [
      isEditor,
      messages.previewAlert,
      setCurrentMessage,
      setLoginOpen,
      setSignUpOpen,
    ],
  );

  if (!(googleUrl || microsoftUrl)) {
    return <></>;
  }

  return (
    <NoSsr>
      <Main>
        <StyledDivider>
          <Typography>OR</Typography>
        </StyledDivider>
        <Content>
          <Label variant="body2">{label}</Label>
          {googleUrl && (
            <StyledThemeButton
              href={googleUrl}
              onClick={(e) => onClick(e, googleUrl, googleProvider)}
              variant="outlined"
              endIcon={<StyledGoogleIcon />}
            >
              <ButtonLabel>GOOGLE</ButtonLabel>
            </StyledThemeButton>
          )}
          {microsoftUrl && (
            <StyledThemeButton
              href={microsoftUrl}
              onClick={(e) => onClick(e, microsoftUrl, microsoftProvider)}
              variant="outlined"
              endIcon={<StyledOutlookIcon />}
            >
              <ButtonLabel>OUTLOOK</ButtonLabel>
            </StyledThemeButton>
          )}
        </Content>
      </Main>
    </NoSsr>
  );
};

export default SSOIntegration;
