import { FunctionComponent } from "react";
import { yupResolver } from "@hookform/resolvers/yup";
import { useForm, Controller } from "react-hook-form";
import * as yup from "yup";
import {
  Checkbox,
  FormControlLabel,
  FormHelperText,
  Icon,
  Link,
  styled,
  Typography,
} from "@mui/material";
import { pageMapping } from "../../builder/mapping/page";
import { builtfirstCommunitySubdomain } from "../../helpers/navigation";
import { useConfiguration } from "../../dataAccess/api/configuration";
import TextField from "./TextField";
import { ThemeButton } from "./ThemeComponents/ThemeButton";
import { FormFieldMessage } from "./FormFieldMessage";
import { CloseButton } from "./CloseButton";
import OnlyMobile from "./OnlyMobile";
import { ButtonProgress } from "./ButtonProgress";
import SSOIntegration from "./SSOIntegration";
import TermsText from "./TermsText";

interface FormData {
  fullName: string;
  signUpOrganizationName: string;
  email: string;
  password: string;
  passwordConfirmation: string;
  termsAndConditions: boolean;
  subscribeToNewsletter: boolean;
}

interface Props {
  onSubmit: (data: FormData) => void;
  loading?: boolean;
  serverError?: string;
  isInvite?: boolean;
  label?: string;
  sublabel?: string;
  setOpenLoginInProfilePopUp?: () => void;
  closePopUp?: () => void;
}

const baseSchema = yup.object().shape({
  fullName: yup.string().required("Full Name is a required field"),
  password: yup
    .string()
    .required("Password is a required field")
    .min(8, "Password mast be at least 8 characters"),
  passwordConfirmation: yup
    .string()
    .required("Confirm Password is a required field")
    .oneOf([yup.ref("password"), null], "Passwords must match"),
  subscribeToNewsletter: yup.boolean(),
});

const fullFieldsSchema = baseSchema.shape({
  email: yup
    .string()
    .transform((value) => value.trim())
    .email("Email must be a valid email")
    .required("Email is a required field"),
  signUpOrganizationName: yup
    .string()
    .required("Company Name is a required field"),
});

const getStyledFormPadding = (
  isCenterAligment: boolean,
  isOnPopup?: boolean,
) => {
  if (isOnPopup) {
    return "4.3rem";
  }

  if (isCenterAligment) {
    return "5rem";
  }
  return "10rem";
};

const getStyledFormMaxWidth = (
  isCenterAligment: boolean,
  isOnPopup?: boolean,
) => {
  if (isOnPopup) {
    return "35rem";
  }

  if (isCenterAligment) {
    return "35%";
  }
  return "100%";
};

export const StyledForm = styled("form")<{ isOnPopup?: boolean }>(({
  theme,
  isOnPopup,
}) => {
  const isCenterAlignment = theme.settings.lockScreens.alignment === "center";

  return {
    padding: "2rem",
    alignSelf: "center",
    display: "flex",
    flexDirection: "column",
    flex: 1,
    [theme.breakpoints.up("md")]: {
      paddingLeft: getStyledFormPadding(isCenterAlignment, isOnPopup),
      paddingRight: getStyledFormPadding(isCenterAlignment, isOnPopup),
      paddingBottom: "2.5rem",
      paddingTop: "2.5rem",
      width: getStyledFormMaxWidth(isCenterAlignment, isOnPopup),
      minHeight: isCenterAlignment ? "75%" : "auto",
      justifyContent: isCenterAlignment ? "center" : "normal",
      borderRadius: isCenterAlignment ? "0.6rem" : "0",
    },
  };
});

const StyledTextField = styled(TextField)(() => ({
  marginBottom: "1.5rem",
}));

const StyledLink = styled(Link)(() => ({
  cursor: "pointer",
}));

const SignUpButton = styled(ThemeButton)(() => ({
  marginBottom: "1rem",
  marginTop: "0.5rem",
}));

export const StyledFormTitle = styled(Typography)<{
  subtitle?: boolean;
  hasSubtitle?: boolean;
}>(({ theme, subtitle, hasSubtitle }) => ({
  alignSelf: "center",
  marginBottom: hasSubtitle ? "0.7rem" : "2rem",
  fontSize: theme.typography[subtitle ? "body1" : "h2"].fontSize,
}));

const SignUpLink = styled(Typography)(() => ({
  alignSelf: "center",
  marginTop: "1rem",
}));

const CheckboxLabel = styled(Typography)(({ theme }) => ({
  lineHeight: "1.3",
  fontSize: theme.typography.body2.fontSize,
}));

const SignUpForm: FunctionComponent<Props> = ({
  loading,
  onSubmit,
  serverError,
  label,
  sublabel,
  isInvite,
  setOpenLoginInProfilePopUp,
  closePopUp,
}: Props) => {
  const { configuration } = useConfiguration();
  let validationSchema = isInvite ? baseSchema : fullFieldsSchema;
  if (!configuration?.customDomain) {
    validationSchema = validationSchema.shape({
      termsAndConditions: yup
        .boolean()
        .oneOf(
          [true],
          "You must accept terms and conditions before continuing.",
        ),
    });
  }
  const { control, formState, handleSubmit } = useForm<FormData>({
    resolver: yupResolver(validationSchema),
    defaultValues: {
      fullName: "",
      signUpOrganizationName: "",
      email: "",
      password: "",
      passwordConfirmation: "",
      termsAndConditions: false,
      subscribeToNewsletter: false,
    },
  });

  return (
    <StyledForm
      isOnPopup={!!setOpenLoginInProfilePopUp}
      onSubmit={handleSubmit(onSubmit)}
    >
      {closePopUp && (
        <OnlyMobile>
          <CloseButton onClick={closePopUp}>
            <Icon>close</Icon>
          </CloseButton>
        </OnlyMobile>
      )}
      <StyledFormTitle variant="h1" hasSubtitle={!!sublabel}>
        {label || "Sign Up"}
      </StyledFormTitle>
      {sublabel && <StyledFormTitle subtitle>{sublabel}</StyledFormTitle>}
      <Controller
        name="fullName"
        control={control}
        render={({ field }) => (
          <StyledTextField
            {...field}
            label={`Full Name ${
              fullFieldsSchema.fields.fullName.required() ? "*" : ""
            }`}
            fullWidth
            error={!!formState.errors.fullName}
            helperText={
              formState.errors.fullName ? formState.errors.fullName.message : ""
            }
          />
        )}
      />
      {!isInvite && (
        <Controller
          name="signUpOrganizationName"
          control={control}
          render={({ field }) => (
            <StyledTextField
              {...field}
              label={`Company Name ${
                fullFieldsSchema.fields.signUpOrganizationName.required()
                  ? "*"
                  : ""
              }`}
              fullWidth
              error={!!formState.errors.signUpOrganizationName}
              helperText={
                formState.errors.signUpOrganizationName
                  ? formState.errors.signUpOrganizationName.message
                  : ""
              }
            />
          )}
        />
      )}
      {!isInvite && (
        <Controller
          name="email"
          control={control}
          render={({ field }) => (
            <StyledTextField
              {...field}
              label={`Email ${
                fullFieldsSchema.fields.email.required() ? "*" : ""
              }`}
              fullWidth
              error={!!formState.errors.email}
              helperText={
                formState.errors.email ? formState.errors.email.message : ""
              }
            />
          )}
        />
      )}
      <Controller
        name="password"
        control={control}
        render={({ field }) => (
          <StyledTextField
            {...field}
            label={`Password ${
              fullFieldsSchema.fields.password.required() ? "*" : ""
            }`}
            type="password"
            fullWidth
            error={!!formState.errors.password}
            helperText={
              formState.errors.password ? formState.errors.password.message : ""
            }
          />
        )}
      />
      <Controller
        name="passwordConfirmation"
        control={control}
        render={({ field }) => (
          <StyledTextField
            {...field}
            label={`Confirm Password ${
              fullFieldsSchema.fields.passwordConfirmation.required() ? "*" : ""
            }`}
            type="password"
            fullWidth
            error={!!formState.errors.passwordConfirmation}
            helperText={
              formState.errors.passwordConfirmation
                ? formState.errors.passwordConfirmation.message
                : ""
            }
          />
        )}
      />
      <SignUpButton
        startIcon={loading ? <ButtonProgress /> : null}
        variant="contained"
        type="submit"
        disabled={loading}
      >
        Sign Up
      </SignUpButton>
      {!configuration?.customDomain && (
        <Controller
          name="termsAndConditions"
          control={control}
          render={({ field }) => (
            <>
              <FormControlLabel
                control={
                  <Checkbox
                    {...field}
                    value={field.value}
                    onChange={(e) => field.onChange(e.target.checked)}
                  />
                }
                label={
                  <CheckboxLabel>
                    <TermsText />
                  </CheckboxLabel>
                }
              />
              {formState.errors.termsAndConditions && (
                <FormHelperText error>
                  {formState.errors.termsAndConditions.message}
                </FormHelperText>
              )}
            </>
          )}
        />
      )}
      {!configuration?.customDomain &&
        configuration?.subdomain === builtfirstCommunitySubdomain && (
          <Controller
            name="subscribeToNewsletter"
            control={control}
            render={({ field }) => (
              <>
                <FormControlLabel
                  control={
                    <Checkbox
                      {...field}
                      value={field.value}
                      onChange={(e) => field.onChange(e.target.checked)}
                    />
                  }
                  label={
                    <CheckboxLabel>
                      Subscribe me to the Builtfirst newsletter
                    </CheckboxLabel>
                  }
                />
                {formState.errors.subscribeToNewsletter && (
                  <FormHelperText error>
                    {formState.errors.subscribeToNewsletter.message}
                  </FormHelperText>
                )}
              </>
            )}
          />
        )}
      {serverError ? (
        <FormFieldMessage color="error">{serverError}</FormFieldMessage>
      ) : (
        <></>
      )}
      <SSOIntegration label="SIGN UP VIA" />
      <SignUpLink>
        Already have an account?{" "}
        {setOpenLoginInProfilePopUp ? (
          <StyledLink onClick={setOpenLoginInProfilePopUp}>Login</StyledLink>
        ) : (
          <StyledLink href={pageMapping.Login.path}>Login</StyledLink>
        )}
      </SignUpLink>
    </StyledForm>
  );
};

export default SignUpForm;
